





























































































































import TextInput from '@/app/ui/components/TextInput/index.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import { Utils } from '@/app/infrastructures/misc/Utils'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import controller from '@/app/ui/controllers/ManageCourierController'
import { CourierHistoryLog } from '@/domain/entities/ManageCourier'
import { Dictionary } from 'vue-router/types/router'
import {
  IHeaderCell,
  IOptions,
  ITableCell,
} from '@/data/infrastructures/misc/interfaces/manageCourier'

interface IDateRange {
  start: string
  end: string
  shortcut: string
}

interface IParameter {
  search: string
  dateRange: IDateRange | null
  column: IOptions
  sort: IOptions
  page: number
  perPage: number
}

@Component({
  components: {
    TextInput,
    DateTimePicker,
    DropdownSelect,
    PaginationNav,
    DataTableV2,
    LoadingOverlay,
  },
})
export default class ManageCourierHistoryLogPage extends Vue {
  controller = controller
  currentDate = Utils.formatDateWithIDLocale(
    new Date().toISOString(),
    'YYYY-MM-DD'
  )

  parameters: IParameter = {
    search: '',
    dateRange: null,
    column: <IOptions>{},
    sort: <IOptions>{},
    page: 1,
    perPage: 10,
  }

  listData: CourierHistoryLog[] = []

  customDateShortcuts = [
    { key: 'chooseDate', label: 'Tentukan Tanggal', value: 0 },
    { key: 'last7Days', label: '7 Hari Terakhir', value: 7 },
    { key: 'last14Days', label: '14 Hari Terakhir', value: 14 },
    { key: 'last30Days', label: '30 Hari Terakhir', value: 30 },
  ]

  columnNameOptions: IOptions[] = [
    {
      label: 'Semua',
      value: '',
    },
    {
      label: 'Driver Name',
      value: 'fullname',
    },
    {
      label: 'Phone Number',
      value: 'phone_number',
    },
    {
      label: 'Courier Type Valid',
      value: 'courier_type_valid',
    },
    {
      label: 'Courier Type',
      value: 'courier_type',
    },
    {
      label: 'Announcement Courier Type',
      value: 'announcement_courier_type',
    },
    {
      label: 'Courier Status',
      value: 'courier_status',
    },
    {
      label: 'Vehicle Type',
      value: 'vehicle_type',
    },
    {
      label: 'Vehicle Plate',
      value: 'vehicle_plate',
    },
    {
      label: 'Partner Name',
      value: 'partner_name',
    },
    {
      label: 'Bank Account Name',
      value: 'bank_account_name',
    },
    {
      label: 'Bank Name',
      value: 'bank_id',
    },
    {
      label: 'Bank Account',
      value: 'bank_account',
    },
    {
      label: 'Vendor ID',
      value: 'vendor_id',
    },
    {
      label: 'Vendor Name',
      value: 'vendor_name',
    }
  ]

  sortOptions: IOptions[] = [
    {
      label: 'Terbaru',
      value: 'DESC',
    },
    {
      label: 'Terlama',
      value: 'ASC',
    },
  ]

  headers = [
    this.headerCellMapper('Tanggal', '200px', '0px', '0px'),
    this.headerCellMapper('Nama Kolom', '200px'),
    this.headerCellMapper('Sebelum', '250px'),
    this.headerCellMapper('Sesudah', '250px'),
    this.headerCellMapper('Diubah Oleh', '250px'),
  ]

  perPageOptions: IOptions[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  created(): void {
    const query = <Record<string, never>>this.$route.query
    this.parameters = {
      search: query.search,
      page: query.page || 1,
      perPage: 10,
      column: this.columnNameOptions.filter(
        option => option.value === query.column
      )[0],
      sort:
        this.sortOptions.filter(option => option.value === query.sortBy)[0] ||
        this.sortOptions[0],
      dateRange: {
        start:
          !this.checkInvalidDate(query.startDate) &&
          !this.checkInvalidDate(query.endDate)
            ? query.startDate
            : '',
        end:
          !this.checkInvalidDate(query.startDate) &&
          !this.checkInvalidDate(query.endDate)
            ? query.endDate
            : '',
        shortcut: '',
      },
    }

    this.fetchCourierHistoryLog()
  }

  get params(): Record<string, string | number> {
    return {
      keyword: this.parameters.search,
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      startDate: this.parameters.dateRange?.start || '',
      endDate: this.parameters.dateRange?.end || '',
      column: this.parameters.column
        ? <string>this.parameters.column.value
        : '',
      sortBy: <string>this.parameters.sort.value,
    }
  }

  get ManageCourierHistoryLogTableData(): ITableCell[][] {
    return this.listData.map(log => [
      this.tableCellMapper(
        Utils.formatTimeZone(
          Utils.formatDateWithIDLocale(
            <string>log.updatedAt,
            'DD MMMM YYYY, HH:mm Z'
          )
        ),
        '200px',
        '0px',
        '0px'
      ),
      this.tableCellMapper(
        this.formatedColumnName(<string>log.column),
        '200px'
      ),
      this.tableCellMapper(
        this.getLogHistoryValue(<string>log.column, <string>log.before),
        '250px'
      ),
      this.tableCellMapper(
        this.getLogHistoryValue(<string>log.column, <string>log.after),
        '250px'
      ),
      this.tableCellMapper(<string>log.updatedBy, '250px'),
    ])
  }

  private fetchCourierHistoryLog(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    controller.getManageCourierHistoryLogList({
      params: {
        ...this.params,
        startDate:
          this.params.startDate && this.params.endDate
            ? this.params.startDate
            : '',
        endDate:
          this.params.startDate && this.params.endDate
            ? this.params.endDate
            : '',
        sortBy: this.params.sortBy,
      },
      courierId: this.$route.params.id,
    })
  }

  private onSearch = Utils.debounce((val: string) => {
    if (val.length === 0 || val.length >= 3) {
      this.fetchCourierHistoryLog(true)
    }
  }, 500)

  private onChangePerPage(perPage: number): void {
    this.parameters.perPage = perPage
    this.fetchCourierHistoryLog(true)
  }

  private onFilter(): void {
    this.fetchCourierHistoryLog(true)
  }

  private onDateFilter(): void {
    if (this.params.startDate && this.params.endDate) {
      this.fetchCourierHistoryLog(true)
    }

    if (!this.params.startDate && !this.params.endDate) {
      this.fetchCourierHistoryLog(true)
    }
  }

  private getLogHistoryValue(
    column: string,
    value: string | number
  ): string | number {
    if (column === 'courier_status') {
      return this.formatedStatus(<string>value)
    }
    return value
  }

  private formatedStatus(status: string): string {
    if (status === 'ACTIVE') {
      return 'Aktif'
    }
    return 'Banned'
  }

  private formatedColumnName(column: string): string {
    let result = ''
    switch (column) {
      case 'fullname':
        result = 'Driver Name'
        break
      case 'phone_number':
        result = 'Phone Number'
        break
      case 'courier_type_valid':
        result = 'Courier Type Valid'
        break
      case 'courier_type':
        result = 'Courier Type'
        break
      case 'announcement_courier_type':
        result = 'Announcement Courier Type'
        break
      case 'courier_status':
        result = 'Courier Status'
        break
      case 'vehicle_type':
        result = 'Vehicle Type'
        break
      case 'vehicle_plate':
        result = 'Vehicle Plate'
        break
      case 'partner_name':
        result = 'Partner Name'
        break
      case 'bank_account_name':
        result = 'Bank Account Name'
        break
      case 'bank_id':
        result = 'Bank Name'
        break
      case 'bank_account':
        result = 'Bank Account'
        break
      
      case 'vendor_id':
        result = 'Vendor ID'
        break

      case 'vendor_name':
        result = 'Vendor Name'
        break
    }
    return result
  }

  private checkInvalidDate(value: string): boolean {
    const tempDate = new Date(value)
    if (tempDate.toString() === 'Invalid Date') {
      return true
    }
    return false
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string,
    paddingRight?: string,
    paddingLeft?: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
        paddingRight: paddingRight || '0px',
        paddingLeft: paddingLeft || '20px',
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string,
    paddingLeft?: string,
    paddingRight?: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
        paddingRight: paddingRight || '0px',
        paddingLeft: paddingLeft || '24px',
      },
    }
  }

  @Watch('params')
  onParamsChanged(
    val: Dictionary<string | (string | null)[] | null | undefined> | undefined
  ): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  @Watch('controller.manageCourierHistoryLogData.logCourierData')
  onChangeListData(val: CourierHistoryLog[]): void {
    this.listData = val
  }
}
